import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Navigation, Footer } from '@frameio/developer-components';
import { isBrowser } from '../utils';

const Container = styled.div({
  display: 'flex',
  margin: '0 auto',
  flexDirection: 'column',
});

export const HorizontalWrapper = styled.div({
  paddingTop: '1rem',
  paddingLeft: 'var(--page-margin)',
  paddingRight: 'var(--page-margin)',
});

const isApp = () => isBrowser() && window.location.pathname.includes('/app');

const Layout = ({ children, ...props }) => (
  <Container {...props}>
    <Navigation columns={1} />
    {children}
    {!isApp() && <Footer columns={1} />}
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
