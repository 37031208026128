export const sizes = {
  xs: 375,
  sm: 768,
  md: 1000,
  lg: 1200,
  xlg: 1440,
  xxlg: 1600,
};

export default Object.keys(sizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: `@media (min-width: ${sizes[key]}px)`,
  }),
  {}
);
